.experiences-logo-container {
    display: flex;
    justify-content: left;
    padding-top: 25px;
}

.experiences-logo {
    display: flex;
    position: fixed;
    border: 1px solid white;
    border-radius: 50%;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    top: 4vh;
}

.experiences-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    margin: 0;
    padding-top: 120px;
}

.experiences-main {
    display: flex;
}

.experiences-right-side {
    flex-basis: 900px;
}

.experiences-title {
    width: 100% !important;
}

.experiences-subtitle {
    width: 95% !important;
}

.experiences-left-side {
    display: flex;
    flex-direction: column;
    flex-basis: 300px;
}

.experiences-image-container {
    width: 320px;
}

.experiences-image-wrapper {
    display: inline-block;
    overflow: hidden;
    width: 100%;
    border-radius: 10%;
}

.experiences-image-wrapper img {
    display: block;
    width: 100%;
}

.experiences-socials {
    padding-top: 50px;
    padding-bottom: 50px;
}

.experiences-socials-mobile {
    display: none;
}

@media (max-width: 1024px) {
    .experiences-logo {
        z-index: 100;
    }

    .experiences-main {
        flex-direction: column;
    }

    .experiences-subtitle {
        padding-top: 20px !important;
    }

    .experiences-left-side {
        flex-basis: 100%;
        order: 1;
    }

    .experiences-right-side {
        flex-basis: 100%;
        order: 2;
    }

    .experiences-image-container {
        padding-bottom: 40px;
    }

    .experiences-socials {
        display: none;
    }

    .experiences-socials-mobile {
        padding-top: 30px;
        display: flex;
    }
}

@media (max-width: 600px) {
    .experiences-container {
        padding-top: 60px;
    }

    .experiences-image-container {
        width: 80%;
    }
}