.blogs-logo-container {
	display: flex;
	justify-content: left;
	padding-top: 25px;
}

.blogs-logo {
	display: flex;
	position: fixed;
	border: 1px solid white;
	border-radius: 50%;
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
	top: 4vh;
	z-index: 999;
}

.blogs-container {
	display: flex;
	flex-direction: column;
	height: 100%;
	margin: 0;
	padding-top: 120px;
}

.projects-main {
	display: flex;
}
.projects-right-side {
	flex-basis: 900px;
}

.projects-title {
	width: 100% !important;
}
.projects-subtitle {
	width: 90% !important;
}

.projects-left-side {
	display: flex;
	flex-direction: column;
	flex-basis: 300px;
}

.projects-image-container {
	width: 350px;
}

.projects-image-wrapper {
	display: inline-block;
	overflow: hidden;
	width: 100%;
	border-radius: 10%;
}

.projects-image-wrapper img {
	display: block;
	width: 100%;
}

@media (max-width: 600px) {
	.blogs-container {
		padding-top: 60px;
	}

	.blogs-blog {
		width: calc(100% / 2);
	}
}
