@import "../../../data/styles.css";

.education {
	display: flex;
}

.education a {
	text-decoration: none;
}

.education-left-side {
	min-width: 20%;
	max-width: 20%;
	border-right: 2px solid #f4f4f5;
	margin-left: 10px;
	margin-right: 10px;
}

.education-right-side {
	flex-basis: 80%;
	min-width: 700px;
	mix-blend-mode: normal;
	border-radius: 20px;
	opacity: 0.8;
	padding: 10px;
}

.education-right-side:hover {
	background: #fafafa;
	opacity: 1;
	transition: background-color 0.3s ease-in-out;
}

.education-date {
	margin-top: 10px;
	font-size: 13px;
	color: var(--tertiary-color);
	text-align: center;
}

.education-logo {
	width: 75px;
	height: 75px;
	display: block;
	margin-left: auto;
	margin-right: auto;
	object-fit: cover;
}

.education-title {
	color: #000000;
	font-size: 16px;
	font-weight: 600;
}

.education-courses {
	padding-top: 10px;
	font-size: 13px;
	color: var(--secondary-color);
	font-weight: 480;
	line-height: 25px;
}

.education-description {
	padding-top: 10px;
	font-size: 15px;
	font-weight: 480;
	line-height: 25px;
}

.education-link {
	padding-top: 10px;
	font-size: 14px;
	color: var(--link-color);
	font-weight: 700;
}

@media (max-width: 1024px) {
	.education-left-side {
		min-width: 10%;
		max-width: 10%;
	}

	.education-right-side {
		flex-basis: 90%;
		min-width: 85%;
		max-width: 85%;
	}

	.education-logo {
		display: none;
	}
}