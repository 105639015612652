@import "../../../data/styles.css";

.project {
	display: flex;
	align-items: center;
}

.project a {
	text-decoration: none;
}

.project-left-side {
	min-width: 20%;
	max-width: 20%;
}

.project-left-side img {
	width: 120%;
	height: 120%; /* Maintains the image's aspect ratio */
	border-radius: 20px;  
	/* flex-basis: 80%; */
	mix-blend-mode: normal;
}

.project-right-side {
	flex-basis: 80%;
	min-width: 700px;
	mix-blend-mode: normal;
	border-radius: 20px;
	opacity: 0.8;
	padding: 30px;
}

.project-right-side:hover {
	background: #fafafa;
	opacity: 1;
	transition: background-color 0.3s ease-in-out;
}

.project-date {
	padding-top: 30px;
	font-size: 15px;
	color: var(--tertiary-color);
}

.project-title {
	color: #000000;
	font-size: 18px;
	font-weight: bolder;
}

.project-description {
	padding-top: 10px;
	font-size: 16px;
	color: var(--primary-color);
	font-weight: 480;
	line-height: 25px;
}

.project-link {
	padding-top: 10px;
	font-size: 14px;
	color: var(--link-color);
	font-weight: 700;
}

@media (max-width: 1024px) {
	.project-left-side {
		min-width: 10%;
		max-width: 10%;
	}

	.project-right-side {
		flex-basis: 90%;
		min-width: 85%;
		max-width: 85%;
	}
}
